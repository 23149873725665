var privacyLayer = (function() {
  var currentIndex;

  return {
    init: function() {
      this.addListener();
    },
    addListener: function() {
      $('.main-nav__close').click($.proxy(this.clickNaviClose, this));
      $('.content-stage-clickshape').click($.proxy(this.clickLayerClickShape, this));
      $('.layer-slider__arrow').click($.proxy(this.clickArrows, this));
      $('.layer-slider__dot').click($.proxy(this.clickSliderDot, this));

      $('.content-stage-clickshape').hammer().bind('swiperight', function(){
        if(currentIndex == 0){
          currentIndex = $('.layer-slider__dot').length - 1;
        }
        else {
          currentIndex--;
        }

        privacyLayer.openLayer(currentIndex);
        privacyLayer.activateSliderDot(currentIndex);
      }).bind('swipeleft', function(){
        if(currentIndex == $('.layer-slider__dot').length - 1){
          currentIndex = 0;
        } else {
          currentIndex++;
        }

        privacyLayer.openLayer(currentIndex);
        privacyLayer.activateSliderDot(currentIndex);
      });
    },
    clickNaviClose: function(){
      this.prepareStages();

      $('body').removeClass('layer-view');
      $('.content-stage-clickshape').removeClass('detail-view').show();
    },
    prepareStages: function(){
      $('.main-nav__close').toggleClass('is-active');
      $('.layer-slider').toggleClass('is-active');
      $('.main-nav__button').toggle();
      $('.lead-story').toggle();
      $('.scroll-hint').toggle();
      $('.footer').toggle();
      $('.section-headline').toggle();
    },
    clickLayerClickShape: function(e){
      if($('body').hasClass('layer-view'))
        return;

      elem = $(e.currentTarget);
      indexNum = elem.data('index');

      currentIndex = indexNum;

      $('body').addClass('layer-view');

      this.prepareStages();
      this.openLayer(indexNum);
      this.activateSliderDot(indexNum);
    },
    clickSliderDot: function(e){
      elem = $(e.currentTarget);
      indexNum = elem.data('index');

      currentIndex = indexNum;

      this.activateSliderDot(indexNum);
      this.openLayer(indexNum)
    },
    clickArrows: function(e){
      elem = $(e.currentTarget);
      if(elem.hasClass('layer-slider__arrow--left')){

        if(currentIndex == 0){
          currentIndex = $('.layer-slider__dot').length - 1;
        }
        else {
          currentIndex--;
        }

      } else {
        if(currentIndex == $('.layer-slider__dot').length - 1){
          currentIndex = 0;
        } else {
          currentIndex++;
        }
      }

      this.openLayer(currentIndex);
      this.activateSliderDot(currentIndex);
    },
    openLayer: function(index){
      elem = $('.js-mobile-show-layer[data-index="' + index + '"]');

      $('.content-stage-clickshape').hide();

      elem.addClass('detail-view').show();

      $('html, body').scrollTop(0);
    },
    activateSliderDot: function(index){
      elem = $('.layer-slider__dot[data-index="' + index + '"]');
      $('.layer-slider__dot').removeClass('is-active');

      elem.addClass('is-active');
    }
  };
})();
