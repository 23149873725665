var browseSidebar = (function() {
  return {
    init: function() {
      if ($(".data-protection__wrapper").length === false) {
        return;
      }

      this.addListener();

      if ($(".data-protection__wrapper").hasClass("load-content")) {
        var pageURL = $(".data-protection__wrapper").data("pageurl");

        $(".sidebar-link").removeClass("is-active");
        var activeSideBarLink = $('.sidebar-link[data-pageurl="' + pageURL + '"]')
        activeSideBarLink.addClass("is-active");

        var trackingPagename = window.history.state && window.history.state.TrackingPage || activeSideBarLink.data('trackingPagename');

        if (!$(".content.data-protection").hasClass("sidebar")) {
          $(".content.data-protection").addClass("sidebar");
        }

        this.loadContentFromUrl(pageURL + "-content", trackingPagename);
      }
    },
    destroy: function() {
      this.removeListener();
    },
    addListener: function() {
      $("body").on(
        "click",
        ".js-desktop-handle-data-protection",
        $.proxy(this.createSideBar, this)
      );

      window.addEventListener("popstate", $.proxy(this.checkPopState, this));
    },
    removeListener: function() {
      $("body").off("click", ".js-desktop-handle-data-protection");
    },
    createSideBar: function(e) {
      $(".glossar-layer").hide();

      if (e.type == "click") {
        e.preventDefault();
        var elem = $(e.currentTarget);
        $(".js-desktop-handle-data-protection").removeClass("is-active");
        elem.addClass("is-active");
        var pageName = elem.data("pagename");
        var pageURL = elem.data("pageurl");
        var trackingPagename = elem.data("trackingPagename");

        this.loadContentFromUrl(pageURL + "-content", trackingPagename);
        this.setHistoryState(pageName, pageURL, trackingPagename);
      }

      if (!$(".content.data-protection").hasClass("sidebar")) {
        $(".content.data-protection").addClass("sidebar");
      }
    },
    loadContentFromUrl: function(url, pagename) {
      $(".data-protection-ajax-content")
        .addClass("is-active")
        .load(url + " .content", function() {
          var lang = url.indexOf('/de/') === -1 ? 'en' : 'de';
          ttt.init('production');
          var trackingContext = { PropApplicationLanguage: lang }
          ttt.pageview("wbm/Privacy_Hub", pagename, null, trackingContext);
          $(".link-extern").attr("target", "_blank");
          $("body, html").scrollTop(0);
        });
    },
    resetSidebar: function(e) {
      $(".content.data-protection").removeClass("sidebar");
      $(".data-protection-ajax-content")
        .html("")
        .removeClass("is-active");
    },
    setHistoryState: function(pageName, pageURL, trackingPagename) {
      var obj = { Page: pageName, Url: pageURL, TrackingPage: trackingPagename };
      window.history.pushState(obj, pageName, pageURL, trackingPagename);
    },
    checkPopState: function(e) {
      var character = e.state;
      if (character == null) {
        this.resetSidebar();
      } else {
        var pageName = character.Page;
        var pageURL = character.Url + "-content";
        var trackingPagename = character.TrackingPage;

        this.createSideBar(e);
        this.loadContentFromUrl(pageURL, trackingPagename);
      }
    }
  };
})();
