var isMobile = false,
  autoScrolling = true,
  fitToSection = true,
  rssLoader,
  lottieAnimations,
  anchorLinks,
  browseSidebar,
  privacyLayer,
  glossarLayer;

$(document).ready(function() {
  if ($("body").hasClass("mobile-device")) {
    isMobile = true;
    autoScrolling = false;
    fitToSection = false;
  }

  xingTracking.init();
  rssLoader.init();
  lottieAnimations.init();
  anchorLinks.init();
  glossarLayer.init();

  if (!isMobile) {
    browseSidebar.init();
  }

  if (isMobile) {
    privacyLayer.init();
  }

  $(".full-page").fullpage({
    autoScrolling: autoScrolling,
    fitToSection: fitToSection,
    onLeave: function(index, nextIndex, direction) {
      sections = $(".full-page").find(".section").length;
      if (index == sections && !isMobile) {
        $.fn.fullpage.setAutoScrolling(true);
        $.fn.fullpage.setFitToSection(true);
      }

      lottieAnimations.pauseAnimationByElementId(
        "lottie-animation-home-" + index
      );
    },
    afterLoad: function(anchorLink, index) {
      sections = $(".full-page").find(".section").length;
      if (index == sections) {
        $.fn.fullpage.setAutoScrolling(false);
        $.fn.fullpage.setFitToSection(false);
      }

      lottieAnimations.playAnimationByElementId(
        "lottie-animation-home-" + index
      );
    }
  });

  $(".btn-scrolldown").click(function() {
    $.fn.fullpage.moveSectionDown();
  });

  $(".main-nav__button").click(function() {
    $(this).toggleClass("is-active");
    $(".main-nav__closed").toggleClass("main-nav__open");
  });

  $(".link-extern").attr("target", "_blank");

  $(".btn-back-to-top").click(function() {
    $("html,body")
      .stop()
      .animate(
        {
          scrollTop: 0
        },
        1000
      );
  });
});

$(window).scroll(function() {
  if (!$("main").hasClass("full-page"))
    lottieAnimations.checkIfAnimationIsInView();
});
