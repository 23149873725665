var anchorLinks = (function() {
  return {
    init: function() {
      this.addListener();
    },
    addListener: function() {
      $('body').on('click', '.js-anchor', $.proxy(this.scrollToAnchor, this));
      $('body').on('click', '.link-anchor', $.proxy(this.scrollToAnchor, this));
    },
    scrollToAnchor: function(e){
      e.preventDefault();
      elem = $(e.currentTarget);
      anchorId = elem.data('anchor');

      contentOffsetTop = $('[data-anchortarget="' + anchorId + '"]').offset().top;
      naviHeight = $('.main-nav').height();

      $('html, body').animate({ scrollTop: contentOffsetTop - naviHeight});
    }
  };
})();
