var lottieAnimations = (function() {
  var BASEURL = $('html').data('baseurl');
  var animationData = [];

  var customSegments = [
    {
      id: 'lottie-animation-home-1',
      segmentFrame: 168,
      endFrame: 217,
    },
    {
      id: 'lottie-animation-security-4',
      segmentFrame: 65,
    },
    {
      id: 'lottie-animation-security-5',
      segmentFrame: 65,
    },
    {
      id: 'lottie-animation-security-6',
      segmentFrame: 54,
    },
    {
      id: 'lottie-animation-security-7',
      segmentFrame: 59,
    },
    {
      id: 'lottie-animation-security-8',
      segmentFrame: 180,
    },
    {
      id: 'lottie-animation-security-9',
      segmentFrame: 50,
    },
  ];

  function playCustomSegment(animationConfig) {
    var customSegment = customSegments.filter(function(item) {
      return item.id === animationConfig.animationId;
    })[0];
    if (customSegment) {
      var lastFrame = animationConfig.lottiePlayer.animationData.op;
      animationConfig.lottiePlayer.playSegments([customSegment.segmentFrame, customSegment.endFrame || lastFrame]);
    } else {
      animationConfig.lottiePlayer.play();
    }
  }

  return {
    init: function() {
      $('.lottie-animation__item').each(function(index, value) {
        elem = $(this);
        lottieAnimations.checkElement(elem);
      });

      if (!$('main').hasClass('full-page'))
        this.checkIfAnimationIsInView();
    },
    checkElement: function(elem) {
      domElementError = false;
      id = elem.data('id');
      fileName = elem.data('filename');

      if (typeof id === 'undefined' || id == '') {
        console.log('ID DATA ATTRIBUTE MISSING');
        domElementError = true;
      }

      if (typeof fileName === 'undefined' || fileName == '') {
        console.log('FILENAME DATA ATTRIBUTE MISSING');
        domElementError = true;
      }

      if (!domElementError) {
        this.getAllAnimations(id, fileName);
      }
    },
    getAllAnimations: function(id, fileName) {
      if ($('#' + id).length == 0) {
        return;
      }

      autoplayBool = $('#' + id).data('autoplay');
      if (autoplayBool === undefined) {
        autoplayBool = true;
      }

      var aniConfig = {
        animationId: id,
        lottiePlayer: undefined,
        lottiePlayerConfig: {
          container: document.getElementById(id),
          renderer: "svg",
          loop: true,
          autoplay: autoplayBool,
          path: BASEURL + "/animations/" + fileName + ".json"
        }
      };

      animationData.push(aniConfig);
      this.loadAndPlayLottieByElementId(id);
    },
    loadAndPlayLottieByElementId: function(id) {
      $.each(animationData, function(key, value) {
        if (value.animationId == id && value.lottiePlayer === undefined) {
          value.lottiePlayer = lottie.loadAnimation(value.lottiePlayerConfig)

          if (value.lottiePlayerConfig.autoplay) {
            playCustomSegment(value);
            $('#' + id).addClass('is-playing');
          }
        }
      })
    },
    playAnimationByElementId: function(id) {
      $('#' + id).addClass('is-playing');

      $.each(animationData, function(key, value) {
        if (value.animationId == id && value.lottiePlayer !== undefined) {
          playCustomSegment(value);
        }
      })
    },
    pauseAnimationByElementId: function(id) {
      $('#' + id).removeClass('is-playing');

      $.each(animationData, function(key, value) {
        if (value.animationId == id && value.lottiePlayer !== undefined) {
          value.lottiePlayer.pause();
        }
      })
    },
    checkIfAnimationIsInView: function() {
      $('.lottie-animation__item').each(function() {
        var visible = $(this).visible(true),
        id = $(this).attr('id');

        lottieAnimations.pauseAnimationByElementId(id);

        if ( visible) {
          $(this).addClass('is-visible');
        } else {
          $(this).removeClass('is-visible');
        }

        if ($(this).hasClass('is-visible') && !$(this).hasClass('is-playing')) {
          lottieAnimations.playAnimationByElementId(id);
        }
      });
    }
  };
})();
