var glossarLayer = (function() {
  var layerElement = $('.glossar-layer');

  return {
    init: function() {
      this.addListener();
    },
    addListener: function(){
      $('body').on('click', '.link-glossar', function(event){
        event.preventDefault();

        elem = $(this);
        url = elem.attr('href');

        glossarLayer.openGlossarByUrl(url);
        if(!$('body').hasClass('mobile-device')){
          glossarLayer.positionLayer(elem);
        } else {
          $('.glossar-layer__close').addClass('is-active');
          $('.main-nav__button').hide();
        }

        return false;
      });

      $('body').on('click', '.close-glossar-layer', function(event){
        glossarLayer.hideGlossarLayer();
      });

      $('body').on('click', '.glossar-layer__close', function(event){
        glossarLayer.hideGlossarLayer();
      });
    },
    openGlossarByUrl: function(url){
      this.hideGlossarLayer();
      layerElement.show();
      layerElement.find('.glossar-layer__item[data-url="' + url + '"]').show();
    },
    hideGlossarLayer: function(){
      if($('body').hasClass('mobile-device')){
        $('.glossar-layer__close').removeClass('is-active');
        $('.main-nav__button').show();
      }

      $('.glossar-layer').hide();
      $('.glossar-layer__item').hide();
    },
    positionLayer: function(elem){
      naviHeight = $('.main-nav').outerHeight();

      layerWidth = layerElement.outerWidth();
      layerHeight = layerElement.outerHeight();

      elemWidth = elem.width();
      elemHeight = elem.height();
      elemOffsetTop = elem.offset().top;
      elemOffsetLeft = elem.offset().left;

      if((layerHeight + naviHeight) < elemOffsetTop){
        layerElement.removeClass('arrow-top');
        $('.glossar-layer').css({'top': elemOffsetTop - layerHeight - 15, 'left': (elemWidth/2) - (layerWidth/2) + elemOffsetLeft});
      } else {
        layerElement.addClass('arrow-top');
        $('.glossar-layer').css({'top': elemOffsetTop + elemHeight + 20, 'left': (elemWidth/2) - (layerWidth/2) + elemOffsetLeft});
      }

    }
  };
})();
