var xingTracking = (function() {
  return {
    init: function() {
      this.addListener();
    },
    addListener: function() {
      $("body").on("click", "[data-actionname]", $.proxy(this.track, this));
    },
    track: function(e) {
      elem = $(e.currentTarget);
      actionName = elem.data("actionname");
      contextData = elem.data("contextdata");

      if (actionName) {
        ttt.event('PropTrackAction', { PropTrackAction: actionName });
      }
    }
  };
})();
