var rssLoader = (function() {
  var locale = $("html").attr("lang") || "de";
  var newsSection = $("section.news");

  return {
    init: function() {
      if ($("section.news").length == 0) return;
      var apiUrl = newsSection.data("endpoint").replace(":locale", locale);

      $.ajax({
        method: "GET",
        url: apiUrl,
        dataType: "json",
      }).done(function(posts) {
        rssLoader.loadPosts(posts);
      });
    },
    loadPosts: function(posts) {
      var postKeys = Object.keys(posts).reverse();
      $.each(postKeys, function(index, postKey) {
        var post = posts[postKey];
        var newsItem = rssLoader.buildPost(post);
        newsSection.append(newsItem);
      });
    },
    buildPost: function(post) {
      var formattedDate = rssLoader.formatDate(post.date);

      var newsWrap = $("<div />", {class: "news__wrap"});
      var newsEntry = $("<div />", {class: "news-entry"});
      var newsFlex = $("<div />", {class: "flex"});
      var newsContent = $("<div />", {class: "news__content"});

      var newsLink = $("<a />", {
        html: post["title"]["rendered"],
        href: post["link"],
      });

      var newsTitleMobile = $("<h2 />", {
        class: "news__headline show-mobile",
        html: newsLink.clone()
      });

      var newsTitle = $("<h2 />", {
        class: "news__headline show-desktop",
        html: newsLink.clone()
      });

      var newsMeta = $("<p />", {
        class: "news__meta show-desktop",
        html: formattedDate + " | " + post["categories"][0]["name"],
      });

      var newsCopy = $("<div />", {
        class: "news__copy",
        html: post["excerpt"]["rendered"],
      });

      var newsMore = $("<a />", {
        class: "link link-more",
        href: post["link"],
        html: $("<div />", {
          class: "btn__inner",
          html: locale === "de" ? "Mehr lesen" : "Read more"
        })
      });

      var newsMedia = $("<div />", {
        class: "news__media"
      });

      var newsMetaMobile = $("<p />", {
        class: "news__meta show-mobile",
        html: formattedDate + " | " + post["categories"][0]["name"],
      });

      var newsImage = $("<img />", {
        class: "img-responsive",
        src: post["featured_media"]["media_details"]["sizes"]["medium"]["source_url"]
      });

      return newsWrap.append(
        newsEntry.append(
          newsFlex.append(
            newsContent.append(newsTitle)
                       .append(newsMeta)
                       .append(newsCopy)
                       .append(newsMore)
          )
          .append(
            newsMedia.append(newsTitleMobile)
                     .append(newsMetaMobile)
                     .append(newsImage)
          )
        )
      );
    },
    formatDate: function(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join(".");
    }
  };
})();
